// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

import ScaffoldLayout from 'src/layouts/ScaffoldLayout'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/add-employee-to-account" page={AddEmployeeToAccountPage} name="addEmployeeToAccount" />
      <Route path="/register/{token:string}" page={RegisterPage} name="register" />
      <Route path="/badge/{id:string}" page={BadgePage} name="badge" />
      <Route path="/employee/{employeeId:Int}" page={EmployeePage} name="employee" />
      <Route path="/employee/{employeeId:Int}/badge/{badgeId:string}" page={EmployeePage} name="employeeWithBadge" />
      <Route path="/login" page={LoginPage} name="login" />
      <Route path="/forgot-password" page={ForgotPasswordPage} name="forgotPassword" />
      <Route path="/reset-password" page={ResetPasswordPage} name="resetPassword" />
      <Route path="/" page={HomePage} name="home" />
      <PrivateSet unauthenticated="login">
        <Route path="/edit-employee/{id:Int}" page={EditEmployeePage} name="editEmployee" />
        <Set wrap={ScaffoldLayout} title="Types d'entrées de cartes de visite" titleTo="adminCardEntryTypes" buttonLabel="Nouveau type d'entrées de cartes de visite" buttonTo="adminNewCardEntryType">
          <Route path="/admin/card-entry-types/new" page={AdminCardEntryTypeNewCardEntryTypePage} name="adminNewCardEntryType" />
          <Route path="/admin/card-entry-types/{id:Int}/edit" page={AdminCardEntryTypeEditCardEntryTypePage} name="adminEditCardEntryType" />
          <Route path="/admin/card-entry-types" page={AdminCardEntryTypeCardEntryTypesPage} name="adminCardEntryTypes" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Entreprises" titleTo="adminCompanies" buttonLabel="Nouvelle entreprise" buttonTo="adminNewCompany">
          <Route path="/admin/companies/new" page={AdminCompanyNewCompanyPage} name="adminNewCompany" />
          <Route path="/admin/companies/{id:Int}/edit" page={AdminCompanyEditCompanyPage} name="adminEditCompany" />
          <Route path="/admin/companies/{companyId:Int}/dashboard" page={CompanyDashboardPage} name="adminCompany" />
          <Route path="/admin/companies" page={AdminCompanyCompaniesPage} name="adminCompanies" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Groupes" titleTo="adminGroups" buttonLabel="Nouveau groupe" buttonTo="adminNewGroup">
          <Route path="/admin/groups/new" page={AdminGroupNewGroupPage} name="adminNewGroup" />
          <Route path="/admin/groups/{id:Int}/edit" page={AdminGroupEditGroupPage} name="adminEditGroup" />
          <Route path="/admin/groups/{id:Int}" page={AdminGroupGroupPage} name="adminGroup" />
          <Route path="/admin/groups" page={AdminGroupGroupsPage} name="adminGroups" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Badges" titleTo="adminBadges" buttonLabel="Nouveau badge" buttonTo="adminNewBadge" buttonCondition={(currentUser) => currentUser.isGlobalAdmin || currentUser.employees.some((employee) => employee.isCompanyAdmin)}>
          <Route path="/admin/badges/new" page={AdminBadgeNewBadgePage} name="adminNewBadge" />
          <Route path="/admin/badges/{id}/edit" page={AdminBadgeEditBadgePage} name="adminEditBadge" />
          <Route path="/admin/badges/{id}" page={AdminBadgeBadgePage} name="adminBadge" />
          <Route path="/admin/badges" page={AdminBadgeBadgesPage} name="adminBadges" />
        </Set>
        <Set wrap={ScaffoldLayout} title="Utilisateurs" titleTo="adminUsers">
          <Route path="/admin/users" page={AdminUserUsersPage} name="adminUsers" />
        </Set>
      </PrivateSet>
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
