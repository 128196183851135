import { Link, routes } from '@redwoodjs/router'

import { useAuth } from '@/auth'

const Nav = () => {
  const { isAuthenticated, loading, currentUser, logOut } = useAuth()
  if (loading) {
    return null // Render nothing when loading
  }
  const isAdmin = isAuthenticated && currentUser && currentUser.isGlobalAdmin
  const isACompanyAdmin =
    isAuthenticated &&
    currentUser.employees.some((employee) => employee.isCompanyAdmin)
  const isAGroupManager =
    isAuthenticated &&
    currentUser.employees.some((employee) => employee.isGroupManager)

  return (
    <header>
      <nav className="flex flex-row flex-nowrap gap-8 p-4">
        {(isAdmin || isACompanyAdmin) && (
          <Link to={routes.adminCompanies()}>Entreprises</Link>
        )}
        {(isAdmin || isAGroupManager) && (
          <Link to={routes.adminGroups()}>Groupes</Link>
        )}
        {(isAdmin || isACompanyAdmin || isAGroupManager) && (
          <Link to={routes.adminBadges()}>Badges</Link>
        )}
        {(isAdmin || isACompanyAdmin) && (
          <Link to={routes.adminCardEntryTypes()}>Types d&apos;entrées</Link>
        )}
        {isAdmin && <Link to={routes.adminUsers()}>Utilisateurs</Link>}
        <div className="ml-auto">
          {isAuthenticated && <button onClick={logOut}>Déconnexion</button>}
          {!isAuthenticated && !loading && (
            <Link to={routes.login()}>Connexion</Link>
          )}
        </div>
      </nav>
    </header>
  )
}

export default Nav
