import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export async function getUserRights() {
  const user = context.currentUser
  if (!user)
    return {
      companiesWhereUserIsAdmin: [],
      groupsWhereUserIsManager: [],
    }
}
