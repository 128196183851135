import { Link, routes } from '@redwoodjs/router'

import { useAuth } from '@/auth'
import Nav from '@/components/Nav/Nav'
import { Button } from '@/components/ui/button'
import { H2 } from '@/components/ui/typrography'

type LayoutProps = {
  title: string
  titleTo: string
  buttonLabel: string
  buttonTo: string
  buttonCondition: (currentUser) => boolean
  children: React.ReactNode
}

const ScaffoldLayout = ({
  title,
  titleTo,
  buttonLabel,
  buttonTo,
  buttonCondition = () => true,
  children,
}: LayoutProps) => {
  const { currentUser } = useAuth()
  return (
    <>
      <Nav />
      <div className="container my-4">
        <header className="flex flex-row justify-between *:align-middle">
          <H2 className="">
            <Link to={routes[titleTo]()} className="">
              {title}
            </Link>
          </H2>
          {routes[buttonTo] && buttonCondition(currentUser) && (
            <Link to={routes[buttonTo]()} className="">
              <Button variant="outline">+ {buttonLabel}</Button>
            </Link>
          )}
        </header>
        <main className="rw-main">{children}</main>
      </div>
    </>
  )
}

export default ScaffoldLayout
