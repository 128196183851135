import { parseJWT } from '@redwoodjs/api'
import { createAuth, createDbAuthClient } from '@redwoodjs/auth-dbauth-web'

// const dbAuthClient = createDbAuthClient({ webAuthn: new WebAuthnClient() })
const dbAuthClient = createDbAuthClient({})

export const { AuthProvider, useAuth } = createAuth(dbAuthClient)

export const getCurrentUser = async (decoded) => {
  return (
    context.currentUser || { ...decoded, roles: parseJWT({ decoded }).roles }
  )
}
